.p-listDetailOder{
    margin-top: 70px;
    &_list{
        max-height: 380px;
        overflow-y: auto;
        background: #F3F5F7;
        margin-top: 10px;
    }
    &_item{
        margin-top: 20px;
    }
    &_wrapButton {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        button{
            height: 30px;
            padding-top: 5px;
        }
    }
    &_text{
        text-align: center;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 700;
    }
}