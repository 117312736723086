.oderItem{
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
    p{
        margin: 0;
    }
    &_table{
        max-height: 200px;
        overflow-y: auto;
        margin-top: 10px;
    }
    table{
        width: 100%;
        thead{
            border-bottom: 1px solid black;
            position: sticky;
            top: 0;
            background: white;
            z-index: 999;
        }
        tbody{
            td{
                padding: 10px 0;
                border-bottom: 1px solid black;
            }
        }
    }
    &_wrapButton{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        button{
            height: 30px;
            padding-top: 5px;
        }
    }
}