.scan-inventory {
  &_content{
      background: #fff;
      padding: 20px;
      box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
      border-radius: 5px;
  }
  &_selectedCamera{
      max-width: 30%;
      margin: auto;
  }
  &_camera{
      max-width: 20%;
      margin: auto;
      margin-top: 20px;
  }
  &_input{
      width: 100%;
      margin: auto;
      margin-top: 20px;
      p{
          text-align: center;
      }
      input {
          padding: 5px;
          padding-right: 30px;
          height: 38px;
          border-radius: 4px;
          outline: none;
          border-color: hsl(0, 0%, 80%);
          border-style: solid;
          border-width: 1px;
          width: 100%;
          &:focus {
            border-color: blue;
            box-shadow: 0 0 0 1px #2684ff;
          }
          background-color: #b9d3f4;
      }
    &_icon {
      position: absolute;
      right: 20px;
      top: 57px;
      font-size: 20px;
    }
  }
  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
  }
  &_history {
    height: 400px;
    overflow: auto;
    overflow-x: hidden;
    h5 {
      font-size: 40px;
    }
  }
}
