.list-account-table {
  &_content {
      max-height: 50vh;
      overflow: auto;
    table {
      width: 100%;
      background-color: white;
      thead{
          position: sticky;
          top: 0;
          z-index: 999;
          background-color: white;
          border-bottom: 1px solid #666666;
          th{
            padding: 10px;
            input{
              padding: 5px;
              height: 38px;
              border-radius: 4px;
              outline: none;
              border-color: hsl(0, 0%, 80%);
              border-style: solid;
              border-width: 1px;
              width: 100%;
              &:focus {
                border-color: blue;
                box-shadow: 0 0 0 1px #2684ff;
              }
            }
            &:first-child, &:nth-child(2){
                min-width: 190px;
            }
            &:nth-child(3){
                min-width: 285px;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6){
                min-width: 80px;
            }
            &:last-child{
              min-width: 210px;
            }
          }
      }
      tbody{
          td{
            padding: 10px;
            border-bottom: 1px solid #666666;
          }
      }
    }
  }

  &_wrapCode{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_buttonCode{
      padding: 5px !important;
      font-size: 13px !important;
  }
}
