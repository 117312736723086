.pagination{
    display: flex;
    align-items: center;
    &_input{
        margin: 0 5px;
        max-width: 50px;
        padding: 5px;
        height: 38px;
        border-radius: 4px;
        outline: none;
        border-color: hsl(0, 0%, 80%);
        border-style: solid;
        border-width: 1px;
        width: 100%;
        &:focus {
          border-color: blue;
          box-shadow: 0 0 0 1px #2684ff;
        }
    }
}