.list-oder-page {
  margin-top: 60px;
  &_buttonDropdown{
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    font-weight: 700;
    display: inline-block;
    &::after{
      content: '';
      position: absolute;
      top: 24%;
      width: 7px;
      height: 7px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      margin-left: 5px;
      transition: all 0.4s;
    }

    &-show{
      &::after{
        transform: rotate(-45deg);
        top: 47%;
      }
    }
  }

  &_wrapDropdown{
    position: relative;
  }

  &_dropdown{
    position: absolute;
    max-width: 200px;
    padding: 5px;
    border-radius: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(18, 38, 63, 0.05);
    z-index: 999;
    right: 0;
    left: 0;
    top: 25px;
    visibility: hidden;
    transition: all 0.25s ease-in;
    &-show{
      visibility: visible;
    }
  }

  &_field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    label{
      margin: 0;
      cursor: pointer;
      margin-right: 10px;
    }
    input{
      cursor: pointer;
    }
  }

  &_table{
    margin-top: 20px;
  }

}
