.table-list-product {

    font-size: 11px;
    width: 100%;
    color: black;
    &,td,tr {
        border: 1px solid black;
        padding: 2px;
    }
    tr:nth-child(1) td {
        text-align: center;
        font-weight: bold;
    }

    td:nth-child(1), td:nth-child(4){
        width: 5%;
        text-align: center;

    }
    td:nth-child(2) {
        width: 42%;
    }    
    td:nth-child(3) {
        width: 18%;
    }   
    td:nth-child(5), td:nth-child(6) {
        width: 15%;
        text-align: right;

    }
}

@page {
    margin: 20px;
}