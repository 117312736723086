.ship-method{
    border-top: 1px solid black;
    margin-top: 10px;
    padding-top: 10px;
}

.loading-custom-primary{
    fill: #fe4500 !important;
    display: inherit;
}

.loading-custom-white{
    fill: #fff !important;
    margin-left: 5px;
}

.loading-custom-page{
    fill: #3B5DE7 !important;
    margin: auto;
    margin-top: 20px;
}

.errorInput{
    border-color: red !important;
}