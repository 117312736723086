.list-item {
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(18, 38, 63, 0.05);

  &_title {
    text-align: center;
  }

  &_input {
    width: 100%;
    padding: 5px;
    height: 38px;
    border-radius: 4px;
    outline: none;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    width: 100%;

    &:focus {
      border-color: blue;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }

  &_list {
    margin-top: 10px;
    height: calc(100vh - 350px) !important;
    overflow-y: auto;
    background: #F3F5F7;
    padding: 5px;
    border-radius: 5px;

    &.mobile {
      height: calc(100vh - 200px) !important;
    }
  }

  &_totalOder {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}