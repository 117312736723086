.page-product-category {
  .left-list-main {
    height: 100% !important;
  
    .left-list-products {
      height: 100% !important;
      overflow-y: scroll;
      display: 'flex' !important;
    }
  }
  .product-name {
    height: 25px;
    line-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}
