.page-carousel {
  .list-carousel {
    height: calc(100vh - 210px);
    overflow-y: scroll;
    .list-pictures {

      overflow-x: scroll;
      overflow-y: hidden;
      height: 150px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
      align-content: flex-start;
      .dropzone-picture {
        height: 150px;
        width: 150px;
      }
      div {
        height: 150px;
        width: 150px;
        .card-picture {
          margin-bottom: 0px !important;
          padding: 0px;
          height: 150px;
          width: 150px;
        }
      }
    }
  }
}