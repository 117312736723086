.oder-manager{
    margin-top: 70px;
    &_content{
        background: #fff;
        padding: 20px;
        box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
        border-radius: 5px;
        height: calc(100vh - 210px);
        overflow-y: auto;
    }
    &_shipInfo{
        margin-top: 20px;
    }
}

.text-right{
    text-align: right;
}

.table-product {
    border-top: 1px solid black;
    margin-top: 10px;
    padding-top: 10px;
    table {
      margin: 0 -5px;
      width: 100%;
      thead {
        th {
          padding: 5px;
          &:nth-child(1) {
            min-width: 150px;
          }
          &:nth-child(2) {
            min-width: 100px;
          }
          &:nth-child(3) {
            width: 85px;
            min-width: 85px;
            max-width: 85px;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            min-width: 100px;
            max-width: 100px;
            width: 100px;
          }
          &:nth-child(7){
            min-width: 20px;
            max-width: 20px;
            width: 20px;
          }
        }
      }
      tbody {
        td {
          padding: 5px;
        }
      }
    }
    &_input {
      padding: 5px;
      height: 38px;
      border-radius: 4px;
      outline: none;
      border-color: hsl(0, 0%, 80%);
      border-style: solid;
      border-width: 1px;
      width: 100%;
      &:focus {
        border-color: blue;
        box-shadow: 0 0 0 1px #2684ff;
      }
      &-error{
        border-color: red;
        &::placeholder{
          color: red;
        }
        &:focus {
          border-color: red;
          box-shadow: 0 0 0 1px red;
        }
      }
    }
  }

  .p-errors {
    color: red;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .list-cost-delivery-header {
    .modal-title {
      width: 100%;
      .cod {
        float: right;
        padding-right: 60px;
      }
    }
  }

  .select2-selection--is-disabled .select2-selection__single-value {
    color: black !important;
  }