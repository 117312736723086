.scan-oder{
    margin-top: 70px;
    &_content{
        background: #fff;
        padding: 20px;
        box-shadow: 0 12px 24px rgba(18, 38, 63, 0.03);
        border-radius: 5px;
    }
    &_selectedCamera{
        max-width: 30%;
        margin: auto;
        position: relative;
        z-index: 9;
    }
    &_camera{
        margin: auto;
        margin-top: 20px;
    }
    &_input{
        max-width: 30%;
        margin: auto;
        margin-top: 20px;
        p{
            text-align: center;
        }
        input {
            padding: 5px;
            height: 38px;
            border-radius: 4px;
            outline: none;
            border-color: hsl(0, 0%, 80%);
            border-style: solid;
            border-width: 1px;
            width: 100%;
            &:focus {
              border-color: blue;
              box-shadow: 0 0 0 1px #2684ff;
            }
        }
    }
}