.temp-list-bill-card {
  display: grid;
  grid-template-columns: auto auto auto auto;

  &.mobile {
    grid-template-columns: auto;
  }

  .temp-bill-card {
    margin: 5px;
  }
}