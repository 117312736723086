.page-product {
  .left-list-main {
    height: 100% !important;
  
    .left-list-products {
      height: 100% !important;
      overflow-y: scroll;
      display: 'flex' !important;
    }
  }
  
  
  .search {
    height: 35px;
    input {
      padding: 5px;
    }
  }
  
  .info-product {
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 2px;
      font-size: 12px;
    }
  }
  .product-name {
    transition-duration: 500ms;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .product {
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.8;
      z-index: 3;
    }
    .list-attribute {
      .picture-attribute {
        .image-upload {
          left: 0px;
          display: none;
        }
        &:hover .image-upload {
          display: block;
          opacity: 0.7;
        }
      }
    }
  }
  .main-list-product-detail {
    height: 100% !important;
    .card-list-product-detail {
      height: 450px;
      margin-bottom: 10px;
  
    }
    .no-product-selected {
      height: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .search-main {
      position: relative;
      .action-right {
        text-align: right;
      }
      .result-search-main {
        position: absolute;
        top: 45px;
        z-index: 2;
        .result-text:hover {
          color: blue;
        }
      }
    }
  }
  
}


.ReactTags__tags {
  position: relative;
  margin-top: 10px;

.ReactTags__tag {
  user-select: text;
}
}

.ReactTags__tagInput {
  border-radius: 5px;
  margin-top: 10px;
  padding: 0 4px;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  padding: 5px;
  height: 38px;
  border-radius: 4px;
  outline: none;
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  width: 100%;
  &:focus {
    border-color: blue;
    box-shadow: 0 0 0 1px #2684ff;
  }
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__selected{
  margin: 0 -5px;
}
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #17db48;
  color: black;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 2px 5px;
  border-radius: 5px;
}
.ReactTags__selected span.ReactTags__tag:first-child {
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}
.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
.ReactTags__suggestions {
  background: white;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  margin: 0;
  padding: 0;
  width: 100%;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
}
.ReactTags__suggestions li mark {
  background: none;
  font-weight: unset;
  text-indent: 0;
  color: unset;
  margin: 0;
  padding: 0;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  &:hover{
    background: #b7cfe0;
    color: white;
  }
  cursor: pointer;
}

.modal-list-product {
  .modal-content {
    .list-pictures {

      overflow-x: scroll;
      overflow-y: hidden;
      height: 150px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
      align-content: flex-start;
      .dropzone-picture {
        height: 150px;
        width: 150px;
      }
      div {
        height: 150px;
        width: 150px;
        .card-picture {
          margin-bottom: 0px !important;
          padding: 0px;
          height: 150px;
          width: 150px;
        }
      }
    }

  }
}

.placeholder-danger {

  .select2-selection__control {
    border:1px solid red;
  }
  .select2-selection__placeholder {
    color: red;
  }
}

.ReactVirtualized__Table__rowColumn {
  margin-left: 1px !important;
  margin-right: 1px !important;
}